import { createStore } from 'vuex'

export default createStore({
  state: {
    loaderstate: false,
    login: {},
    lastlogin: false,
    settings: [],
    shops: null,
    productcategories: null,
    dashboardusers: null,
    alertcounts: {}
  },
  getters: {
    get_loaderstate: state => { return state.loaderstate; },
    get_login: state => { return state.login; },
    get_lastlogin: state => { return state.lastlogin; },
    get_settings: state => { return state.settings; },
    get_shops: state => { return state.shops; },
    get_productcategories: state => { return state.productcategories; },
    get_dashboardusers: state => { return state.dashboardusers; },
    get_alertcounts: state => { return state.alertcounts; }
  },
  mutations: {
    SET_LOADERSTATE(state, val) { state.loaderstate = val; },
    SET_LOGIN(state, val) { state.login = val; },
    SET_LASTLOGIN(state, val) { state.lastlogin = val; },
    SET_SETTINGS(state, val) { state.settings = val; },
    SET_SHOPS(state, val) { state.shops = val; },
    SET_PRODUCTCATEGORIES(state, val) { state.productcategories = val; },
    SET_DASHBOARDUSERS(state, val) { state.dashboardusers = val; },
    SET_ALERTCOUNTS(state, val) { state.alertcounts = val; }
  },
  actions: {
    set_loaderstate({ commit }, val) { commit('SET_LOADERSTATE', val); },
    set_login({ commit }, val) { commit('SET_LOGIN', val); },
    set_lastlogin({ commit }, val) { commit('SET_LASTLOGIN', val); },
    set_settings({ commit }, val) { commit('SET_SETTINGS', val); },
    set_shops({ commit }, val) { commit('SET_SHOPS', val); },
    set_productcategories({ commit }, val) { commit('SET_PRODUCTCATEGORIES', val); },
    set_dashboardusers({ commit }, val) { commit('SET_DASHBOARDUSERS', val); },
    set_alertcounts({ commit }, val) { commit('SET_ALERTCOUNTS', val); }
  }
})